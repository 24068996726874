
import {
  defineComponent,
  reactive,
  computed,
  onBeforeMount,
  ref,
  inject
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/http'
import { useStore } from 'vuex'
import { device, employee, modalInstance, notifyInstance } from '@/type'
import moment from 'moment/moment'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const employees = ref(new Array<employee>())
    const devices = ref(new Array<device>())

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)
    const orderTypesMap = computed(() => globalVariable.value.orderPosTypes)
    const discountsMap = computed(() => globalVariable.value.discounts)

    const type = route.params.type
    const date = route.params.date
    const handOverId = route.params.handOverId
    const checkOutId = route.params.checkOutId
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const subject = type === 'handOver' ? '小結' : '關帳'
    const activeSection = reactive<string[]>([
      '結算人員',
      '訂單資訊',
      '折扣資訊',
      '錢櫃資訊',
      '支付資訊'
    ])
    const exportType = ref('HandoverDetail')

    const minStartAt = ref('')
    const maxEndAt = ref('')
    const handOverRecords = ref(new Array<any>())
    const orderRecords = ref(new Array<any>())
    const discountRecords = ref(new Array<any>())
    const paymentRecords = ref(new Array<any>())
    const totalAmount = ref(0)
    const totalStoreAmount = ref(0)
    const totalWebAmount = ref(0)
    const totalOverShort = ref(0)
    const totalDiscount = ref(0)
    const totalStorePreAmount = ref(0)
    const totalStorePartialPickupAmount = ref(0)
    const totalVoidQuantity = ref(0)

    onBeforeMount(() => {
      if (
        type === undefined ||
        date === undefined ||
        (handOverId === undefined && checkOutId === undefined)
      ) {
        router.push({
          name: 'handover'
        })
      } else {
        init()
      }
    })

    function init() {
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      const initDevice = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })

      const getDetail = new Promise((resolve, reject) => {
        const id = type === 'handOver' ? handOverId : checkOutId
        axios
          .get(`/handovers/${storeId.value}/detail/${id}`, {
            params: {
              type: type
            }
          })
          .then((res) => {
            const data = res.data
            minStartAt.value = data.minStartAt
            maxEndAt.value = data.maxEndAt
            handOverRecords.value = data.handOverRecords
            orderRecords.value = data.orderRecords
            discountRecords.value = data.discountRecords
            paymentRecords.value = data.paymentRecords
            totalAmount.value = data.totalAmount
            totalStoreAmount.value = data.totalStoreAmount
            totalWebAmount.value = data.totalWebAmount
            totalOverShort.value = data.totalOverShort
            totalDiscount.value = data.totalDiscount
            totalStorePreAmount.value = data.totalStorePreAmount
            totalStorePartialPickupAmount.value =
              data.totalStorePartialPickupAmount
            totalVoidQuantity.value = data.totalVoidQuantity
            resolve(data)
          })
          .catch((err) => {
            notify(
              {
                classType: 'error',
                message: `資訊載入失敗，${err.response.errors.detail}`,
                closeAble: true
              },
              60000
            )
            reject(err)
          })
      })
    }

    const toggleActiveSection = (title: string) => {
      const index = activeSection.indexOf(title)

      if (index > -1) {
        activeSection.splice(index, 1)
      } else {
        activeSection.push(title)
      }
    }

    const isActiveSection = computed(() => {
      return (title: string): boolean => {
        return activeSection.includes(title)
      }
    })

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function formatNumber(number: any) {
      return Number(number).toLocaleString('en-US')
    }

    function exportExcelClick() {
      const id = type === 'handOver' ? handOverId : checkOutId

      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            type: type,
            id: id
          },
          modal,
          notify,
          router
        )
      }
    }

    return {
      subject,
      activeSection,
      isActiveSection,
      toggleActiveSection,
      date,
      maxEndAt,
      minStartAt,
      handOverRecords,
      orderRecords,
      discountRecords,
      paymentRecords,
      paymentsMap,
      orderTypesMap,
      discountsMap,
      getEmployeeName,
      formatDateTime,
      getDeviceName,
      formatNumber,
      totalAmount,
      totalStoreAmount,
      totalWebAmount,
      totalOverShort,
      totalDiscount,
      totalStorePreAmount,
      totalStorePartialPickupAmount,
      totalVoidQuantity,
      exportExcelClick
    }
  }
})
