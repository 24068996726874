
import { defineComponent, ref, inject, computed } from 'vue'
import {
  notifyInstance,
  handOverRecord,
  checkOutRecord,
  employee,
  modalInstance,
  device
} from '@/type'
import axios from '@/http'
import { useStore } from 'vuex'
import DatePicker from '@/views/components/datePicker.vue'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

export default defineComponent({
  components: {
    DatePicker
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const employees = ref(new Array<employee>())
    const devices = ref(new Array<device>())

    const checkOutRecords = ref(new Array<checkOutRecord>())
    const handOverRecords = ref(new Array<handOverRecord>())
    const exportType = ref('Handover')

    const date = ref(
      route.params.date
        ? moment(route.params.date).startOf('day').format('YYYY-MM-DD')
        : moment().startOf('day').format('YYYY-MM-DD')
    )

    function getHandoverRecords() {
      axios
        .get(`/handovers/${storeId.value}`, {
          params: {
            date: date.value
          }
        })
        .then((res) => {
          const data = res.data
          handOverRecords.value = data.handOverRecords
          checkOutRecords.value = data.checkOutRecords
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }
    function init() {
      getHandoverRecords()
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      const initDevice = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    init()

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            date: date.value
          },
          modal,
          notify,
          router
        )
      }
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function formatNumber(number: any) {
      return Number(number).toLocaleString('en-US')
    }

    function updateDate(updateDate: any) {
      date.value = moment(updateDate).format('YYYY-MM-DD')
      getHandoverRecords()
    }

    function nextDate() {
      date.value = moment(date.value).add(1, 'day').format('YYYY-MM-DD')
    }

    function preDate() {
      date.value = moment(date.value).subtract(1, 'day').format('YYYY-MM-DD')
    }

    function handOverRecord(id: any) {
      return (
        handOverRecords.value.find((handOverRecord: handOverRecord) => {
          return parseInt(handOverRecord.handOverId) === parseInt(id)
        }) ?? null
      )
    }

    function total(handOver: Array<any>, type: any) {
      return handOver.reduce(function (sum, current) {
        return sum + current[type]
      }, 0)
    }

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    const checkOutRecordsLength = computed(() => checkOutRecords.value.length)
    const handOverRecordsLength = computed(() => {
      const handOverRecordsLength = handOverRecords.value.length
      const checkOutRecordsLength = checkOutRecords.value.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.handOvers?.length ?? 0)
        },
        0
      )
      return handOverRecordsLength + checkOutRecordsLength
    })

    const totalOverShort = computed(() => {
      const handOverRecordsTotalOverShort = handOverRecords.value.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.totalOverShort ?? 0)
        },
        0
      )
      const checkOutRecordsTotalOverShort = checkOutRecords.value.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue.handOvers?.reduce((acc, cur) => {
              return acc + (cur.totalOverShort ?? 0)
            }, 0) ?? 0)
          )
        },
        0
      )
      return handOverRecordsTotalOverShort + checkOutRecordsTotalOverShort
    })

    const totalVoidedQuantity = computed(() => {
      const handOverRecordsTotalVoidedQuantity = handOverRecords.value.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.totalVoidedQuantity ?? 0)
        },
        0
      )
      const checkOutRecordsTotalVoidedQuantity = checkOutRecords.value.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue.handOvers?.reduce((acc, cur) => {
              return acc + (cur.totalVoidedQuantity ?? 0)
            }, 0) ?? 0)
          )
        },
        0
      )
      return (
        handOverRecordsTotalVoidedQuantity + checkOutRecordsTotalVoidedQuantity
      )
    })

    const totalAmount = computed(() => {
      const handOverRecordsTotalAmount = handOverRecords.value.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.totalAmount ?? 0)
        },
        0
      )
      const checkOutRecordsTotalAmount = checkOutRecords.value.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue.handOvers?.reduce((acc, cur) => {
              return acc + (cur.totalAmount ?? 0)
            }, 0) ?? 0)
          )
        },
        0
      )
      return handOverRecordsTotalAmount + checkOutRecordsTotalAmount
    })

    const totalVoidedAmount = computed(() => {
      const handOverRecordsTotalVoidedAmountt = handOverRecords.value.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.totalVoidedAmount ?? 0)
        },
        0
      )
      const checkOutRecordsTotalVoidedAmount = checkOutRecords.value.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue.handOvers?.reduce((acc, cur) => {
              return acc + (cur.totalVoidedAmount ?? 0)
            }, 0) ?? 0)
          )
        },
        0
      )
      return (
        handOverRecordsTotalVoidedAmountt + checkOutRecordsTotalVoidedAmount
      )
    })

    return {
      date,
      updateDate,
      init,
      getEmployeeName,
      getDeviceName,
      formatDateTime,
      formatNumber,
      nextDate,
      preDate,
      handOverRecords,
      checkOutRecords,
      total,
      exportExcelClick,
      checkOutRecordsLength,
      handOverRecordsLength,
      totalOverShort,
      totalVoidedQuantity,
      totalAmount,
      totalVoidedAmount
    }
  }
})
